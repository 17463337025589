/* You can add global styles to this file, and also import other style files */

// Component Library styles
@use 'se-fe-styles/styles/colors.variables';
@use 'se-fe-styles/styles/spacing.variables';
@use 'se-fe-styles/styles/fonts.variables';
@use 'se-fe-styles/styles/typography.variables';

.boss-avatar .se-fe-avatar {
  background-color: #fff;
}

.boss-avatar .se-fe-avatar > div {
  background-size: auto !important;
  background-position: center !important;
}

.page {
  background: #fff;
  padding: spacing.get(700);

  h1 {
    font-family: typography.$default-font-family;
    font-size: fonts.size(800);
  }
}

hr.spacer {
  margin: 12px 0;
}

header.actionable {
  display: flex;
  h1, h2, h3 {
    flex: 1 0 0;
  }
}

se-user-tabs {
  display: block;
  width: 100%;
}

table.outlined {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  td {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding: 3px;
  }
}
